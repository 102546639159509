.theme-2024050,
.theme-monitor\.watchmedier\.dk,
.theme-byrummonitor\.dk,
.theme-2024100,
.theme-uddannelsesmonitor\.dk,
.theme-skolemonitor\.dk,
.theme-sundhedsmonitor\.dk,
.theme-klimamonitor\.dk,
.theme-kulturmonitor\.dk,
.theme-idraetsmonitor\.dk,
.theme-turistmonitor\.dk,
.theme-socialmonitor\.dk,
.theme-seniormonitor\.dk {
  --font-size-xs: 0.675rem;
  --font-size-sm: 0.875rem;
  --font-size-base: 1rem;
  --font-size-lg: 1.125rem;
  --font-size-xl: 1.25rem;
  --font-size-2xl: 1.3125rem;
  --font-size-3xl: 1.375rem;
  --font-size-4xl: 1.75rem;
  --font-size-5xl: 2rem;
  --font-size-6xl: 2.625rem;
  --font-size-7xl: 2.875rem;
  --font-size-8xl: 3rem;

  --line-height-xs: 1.15;
  --line-height-sm: 1.15;
  --line-height-base: 1.15;
  --line-height-lg: 1.15;
  --line-height-xl: 1.25;
  --line-height-2xl: 1.1;
  --line-height-3xl: 1.1;
  --line-height-4xl: 1.05;
  --line-height-5xl: 1.05;
  --line-height-6xl: 1.05;
  --line-height-7xl: 1.05;
  --line-height-8xl: 1.05;

  --letter-spacing-xs: 0.045em;

  --theme-bold: 500;
  --theme-heading-weight: 700;

  --font-body: var(--font-family-flama);
  --font-heading: var(--font-family-capitolium);
  --theme-font-article-body: var(--font-heading);
  --theme-font-article-heading: var(--font-body);

  --theme-color-highlight: 44 93% 48%;
  --theme-color-muted: var(--theme-color-primary-hover);

  --theme-color-accent: var(--theme-color-primary-hover);
  --theme-color-accent-hover: from hsl(var(--theme-color-accent)) h s
    calc(l - 5);

  --theme-color-secondary-hover: from hsl(var(--theme-color-secondary-base)) h s
    calc(l - 5);

  --theme-grid-xs: 1px;
  --theme-grid-lg: 5px;

  --theme-grid-primary-color: 0 0% 0%;
  --theme-grid-secondary-color: 0 0% 80%;

  --theme-font-size-badge: 0.625rem;
  --theme-letter-spacing-badge: 0.0625rem;
}

.theme-2024050,
.theme-monitor\.watchmedier\.dk,
.theme-byrummonitor\.dk {
  --theme-color-primary-base: 221 43% 38%;
  --theme-color-primary-hover: 213 100% 18%;

  --theme-color-secondary-base: 221 55% 89%;

  --theme-color-muted: 220 44% 19%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-secondary-base);
}

.theme-skolemonitor\.dk {
  --theme-color-primary-base: 168 40% 36%;
  --theme-color-primary-hover: 180 100% 20%;

  --theme-color-secondary-base: 180 80% 85%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-secondary-base);
}

.theme-sundhedsmonitor\.dk {
  --theme-color-primary-base: 340 60% 30%;
  --theme-color-primary-hover: 340 100% 10%;

  --theme-color-secondary-base: 220 80% 90%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-secondary-base);
}

.theme-klimamonitor\.dk {
  --theme-color-primary-base: 190 40% 30%;
  --theme-color-primary-hover: 190 100% 10%;

  --theme-color-secondary-base: 190 60% 85%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-secondary-base);
}

.theme-kulturmonitor\.dk {
  --theme-color-primary-base: 45 70% 35%;
  --theme-color-primary-hover: 45 60% 30%;

  --theme-color-secondary-base: 45 80% 75%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-secondary-base);
}

.theme-idraetsmonitor\.dk {
  --theme-color-primary-base: 20 70% 35%;
  --theme-color-primary-hover: 20 60% 30%;

  --theme-color-secondary-base: 20 80% 85%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-secondary-base);
}

.theme-turistmonitor\.dk {
  --theme-color-primary-base: 0 80% 60%;
  --theme-color-primary-hover: 0 70% 50%;

  --theme-color-secondary-base: 0 100% 90%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-secondary-base);
}

.theme-socialmonitor\.dk {
  --theme-color-primary-base: 290 50% 30%;
  --theme-color-primary-hover: 290 50% 20%;

  --theme-color-secondary-base: 270 20% 80%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-secondary-base);
}

.theme-seniormonitor\.dk {
  --theme-color-primary-base: 5 40% 30%;
  --theme-color-primary-hover: 5 40% 20%;

  --theme-color-secondary-base: 5 60% 75%;

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-secondary-base);
}

.theme-uddannelsesmonitor\.dk,
.theme-2024100 {
  --theme-color-primary-base: 120 79% 17%; /* #094E09 */
  --theme-color-primary-hover: 120 82% 13%; /* #063E06 */

  --theme-color-secondary-base: 120 17% 75%; /* #B5CAB5 */

  --theme-color-site-logo-primary: var(--theme-color-primary-base);
  --theme-color-site-logo-secondary: var(--theme-color-secondary-base);
}
